<template>
    <div>
        <w-data-table
            hide-default-header
            outlined
            :loading="loading"
            :headers="headers"
            :items="collection.data"
            :server-items-length="collection.total"
            searchable
            @change:options="fetchItems"
            @click:row="onRowClick"
        >
            <template #[`item.name`]="{item}">
                <member-full-name
                    :item="item"
                />
            </template>

            <template #[`item.lesson`]="{item}">
                <course-teacher-capacity
                    :capacity="item.capacity"
                    short
                />
            </template>

            <template #[`item.actions`]>
                <w-icon color="primary" value="NEXT"/>
            </template>
        </w-data-table>
        <w-aside-drawer
            v-model="isAsideDrawer"
            :title="asideDrawerTitle"
        >
            <div
                v-if="asideDrawerItem"
                class="py-4 px-5"
            >
                <div class="text-button">{{ course.title }}</div>
                <v-divider class="mt-2 mb-4"/>
                <course-teacher-capacity
                    :capacity="asideDrawerItem.capacity"
                />
            </div>
        </w-aside-drawer>
    </div>
</template>

<script>
import MemberFullName from '@/components/Member/MemberFullName'
import { getTeachersCollection } from '@apps/school/api/teachers'
import CourseTeacherCapacity from '@apps/school/components/Course/CourseTeacherCapacity'
import { memberFullName } from '@/components/Member/utils'

export default {
    name: 'CourseTeachers',
    components: { CourseTeacherCapacity, MemberFullName },
    props: {
        uuid: {
            type: String,
            required: true
        }
    },
    computed: {
        headers() {
            return [
                {
                    text: this.$trans('Name'),
                    value: 'name'
                },
                {
                    text: this.$trans('Lesson type'),
                    value: 'lesson'
                },
                {
                    value: 'actions',
                    align: 'right'
                }
            ]
        }
    },
    data() {
        return {
            loading: false,
            isAsideDrawer: false,
            asideDrawerTitle: null,
            asideDrawerItem: null,
            course: null,
            collection: {
                data: []
            }
        }
    },
    methods: {
        fetchItems(payload) {
            this.loading = true

            payload = payload || {}
            payload.course = this.uuid

            getTeachersCollection(payload)
                .then(response => {
                    this.collection = response.data.data
                    this.course = response.data.course
                    this.loading = false
                })
        },
        onRowClick(item) {
            this.asideDrawerTitle = memberFullName(item)
            this.asideDrawerItem = item
            this.isAsideDrawer = true
        }
    }

}
</script>

<style lang=scss>

</style>
