<template>
    <div>
        <div
            v-for="(cap, index) in capacityComputed"
            :key="`capacity${index}`"
        >
            <v-divider v-if="index > 0" class="my-2"/>
            <div class="d-flex">
                <div>
                    <div>{{ cap.title }}</div>
                    <div class="caption">
                        <label class="font-weight-semibold">{{ $trans('Groups') }}: </label>{{ cap.groups }}
                    </div>
                </div>
                <div v-if="cap.other" class="ml-1">
                    <v-chip
                        color="warning"
                        outlined
                        small
                        label
                        class="pa-1"
                    >
                        {{ cap.other }}
                    </v-chip>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CourseTeacherCapacity',
    props: {
        capacity: {
            type: Array,
            default: function () {
                return []
            }
        },
        short: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        capacityComputed() {
            const lessonTypes = {}

            this.capacity.forEach(o => {
                if (!lessonTypes[o.lesson_type_uuid]) {
                    lessonTypes[o.lesson_type_uuid] = {
                        title: o.lesson_type.description
                            ? o.lesson_type.description
                            : o.lesson_type.title,
                        groups: null
                    }
                }

                const prevGroups = lessonTypes[o.lesson_type_uuid].groups
                const currGroups = o.groups.map(g => g.title).join(', ')

                lessonTypes[o.lesson_type_uuid].groups = prevGroups
                    ? [prevGroups, currGroups].join(', ')
                    : currGroups
            })

            const result = []

            for (const lessonTypesKey in lessonTypes) {
                let other = null

                if (this.short && Object.keys(lessonTypes).length > 1) {
                    other = `+${Object.keys(lessonTypes).length - 1}`
                }

                lessonTypes[lessonTypesKey].other = other

                result.push(lessonTypes[lessonTypesKey])
            }

            return this.short
                ? result.slice(0, 1)
                : result
        }
    }
}
</script>

<style lang=scss>

</style>
